<template>
  <div>
    <section>
      <h1>{{ $t("Profile.team.teamMembers") }}</h1>
      <section style="width: 80%; margin-left: auto; margin-right: auto">
        <div style="display: flex; justify-content: end">
          {{ $t("Profile.team.remainingSeats") }} {{ remainingSeats }} /
          {{ team_seats }}
        </div>
        <section>
          <div class="row_title">
            <p class="large_column">E-mail</p>
            <p class="large_column">{{ $t("Profile.team.name") }}</p>

            <p class="medium_column">{{ $t("Profile.team.role") }}</p>
            <p class="medium_column">Statut</p>
            <p class="small_column"></p>
          </div>
          <div class="row_container">
            <div v-for="user in users" :key="user._id" class="rows">
              <p class="large_column">{{ user.usr_email }}</p>
              <p class="large_column">
                {{ user.usr_firstname }} {{ user.usr_lastname }}
              </p>

              <p class="medium_column">{{ roleLabel(user.team.role) }}</p>
              <p class="medium_column">
                {{ statusLabel(user.team.activated) }}
              </p>
              <!-- <p
                
                
                @click="removeUserTeam(user._id)"
              >
                Retirer
              </p>-->
              <div class="small_column">
                <DotsVdoMenu v-if="user.team.role != 0" class="small_column threeDots" :lines="buttonsThreeDots(user)"
                  :red="[$t('delete')]" @clicked="jobTreeDotClicked($event, user)" />
                <p v-else></p>
              </div>
            </div>

            <div v-for="invite in invitationList" :key="invite" class="rows">
              <p class="large_column">{{ invite }}</p>
              <p class="large_column">-</p>
              <p class="medium_column">-</p>
              <p class="medium_column">
                {{ $t("Profile.team.status.pending") }}
              </p>
              <div class="small_column">
                <DotsVdoMenu class="small_column threeDots" :lines="buttonsThreeDots()"
                  :red="[$t('Profile.team.contextualMenu.cancel')]"
                  @clicked="jobTreeDotClicked($event, { usr_email: invite })" />
              </div>
            </div>
          </div>
        </section>
        <section id="addMember" v-if="remainingSeats != 0">
          <h2>{{ $t("Profile.team.addMember") }}</h2>
          <div>
            <input type="text" id="invite_member_input_text" v-model="invite" placeholder="E-mail"
              v-on:keyup.enter="$refs.inviteMemberToTeamAction.click()" />
            <img src="../../../assets/send_arrow.png" alt="" id="inviteMemberToTeamAction"
              ref="inviteMemberToTeamAction" @click="sendInvite" class="arrow"
              :class="[invite.trim() == '' ? 'arrow_disabled' : '']" />
          </div>
        </section>
        <button v-else id="subscription" @click="openBillingPortal">Gérer mon abonnement</button>
      </section>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery"

import Vuex from "vuex";

import DotsVdoMenu from "../../../components/Object/ContextualMenu/ContextualMenu.vue";
export default {
  data() {
    return {
      users: [],
      team_seats: 0,
      invitationList: [],
      invite: "",
    };
  },
  components: { DotsVdoMenu },
  mounted() {
    this.init()
  },
  computed: {
    ...Vuex.mapGetters(["getCurrentUser"]),
    remainingSeats() {
      let nb =
        this.team_seats -
        this.users.filter((u) => u.team.activated == true).length -
        this.invitationList.length;
      if (nb < 0) {
        return 0;
      } else {
        return nb;
      }
    },
  },
  watch: {
    getCurrentUser() {
      this.init()
    }
  },
  methods: {
    init() {
      axios
        .get("/teams/" + this.getCurrentUser.team.id, { params: { users: true } })
        .then((res) => {

          this.users = res.data.Users;
          this.team_seats = res.data.team_seats;

          this.invitationList = res.data.invitationList;
        });
    },
    roleLabel(role_id) {
      if (role_id == 0) {
        return this.$t("Profile.team.roleLabel.owner");
      } else {
        return this.$t("Profile.team.roleLabel.member");
      }
    },
    statusLabel(bool) {
      if (bool) {
        return this.$t("Profile.team.status.enabled");
      } else {
        return this.$t("Profile.team.status.disabled");
      }
    },
    deleteInvite(email) {
      axios
        .delete("/teams/" + this.getCurrentUser.team.id + "/invite", {
          data: { email },
        })
        .then(() => {
          let idx = this.invitationList.findIndex((invite) => invite == email);
          this.invitationList.splice(idx, 1);
          this.Toaster.success(this.$t('Profile.team.success.invitCancel'));
        });
    },
    sendInvite() {
      //E-MAIL FORMAT VERIFICATION
      if (/^\S+@\S+\.\S+$/.test(this.invite) == false) {
        this.Toaster.warn(this.$t('Profile.team.warn.invalide_e_mail_format'))
        return;
      }

      //UNFOCUS INPUT TEXT
      $('#invite_member_input_text').blur()

      axios
        .post("/teams/" + this.getCurrentUser.team.id + "/invite", {
          email: this.invite,
        })
        .then(() => {
          this.invitationList.push(this.invite);
          this.$nextTick(() => {
            this.invite = "";
            this.Toaster.success(this.$t('Profile.team.success.invitSent'));
          });
        })
        .catch(err => {
          if (err && err.response && err.response.data) {
            if (err.response.data == "User is already in the team") {
              this.Toaster.warn(this.$t("Profile.team.userAlreadyInTeam"))
            } else if (err.response.data == "Invitation already sent") {
              this.Toaster.info(this.$t("Profile.team.inviteAlreadySent"))
            } else {
              this.Toaster.error()
            }
          } else {
            this.Toaster.error()
          }
        })
    },
    removeUserTeam(usr_id) {
      axios
        .delete("/teams/" + this.getCurrentUser.team.id + "/users/" + usr_id)
        .then(() => {
          let idx = this.users.findIndex((u) => u._id == usr_id);

          if (idx != -1) {
            this.users.splice(idx, 1);
          }
        });
    },
    activeUser(usr_id) {
      if (this.remainingSeats != 0) {
        axios
          .post("/teams/" + this.getCurrentUser.team.id + "/users/" + usr_id)
          .then(() => {
            let idx = this.users.findIndex((u) => u._id == usr_id);

            this.users[idx].team.activated = true;
          })
          .catch((err) => console.log(err));
      } else {
        this.Toaster.error(this.$t('Profile.team.error.missingSeat'));
      }
    },
    buttonsThreeDots(user) {
      let array = [];
      if (user != undefined) {
        if (!user.team.activated) {
          array.push(this.$t("Profile.team.contextualMenu.enable"));
        }

        array.push(this.$t("delete"));
      } else {
        array.push(this.$t("cancel"));
      }

      return array;
    },
    jobTreeDotClicked(event, user) {
      var choice = event.line;

      switch (choice) {
        case this.$t("delete"):
          this.removeUserTeam(user._id);
          break;
        case this.$t("cancel"):
          this.deleteInvite(user.usr_email);
          break;
        case this.$t("Profile.team.contextualMenu.enable"):
          this.activeUser(user._id);
          break;
      }
    },
    openBillingPortal() {
      axios.get(`/users/${this.getCurrentUser._id}/billing-portal`)
        .then(res => {
          if (res.data && res.data.url) {
            document.location.href = res.data.url;
            // window.open(res.data.url, '_blank').focus();
          } else {
            this.Toaster.error(this.$t("Toaster.error.billing_portal"))
          }
        })
        .catch(() => {
          this.Toaster.error(this.$t("Toaster.error.billing_portal"))
        })
    },
  },
};
</script>

<style scoped>
#subscription {
  padding: 8px 32px;
  gap: 8px;
  width: 212px;
  height: 34px;
  /* Main color/Yopbox Blue */
  background: #3eb1c8;
  border: 0px;
  border-radius: 10px;
  color: white;
  margin: 2% 0%;
  cursor: pointer;
}

h1 {
  margin-left: 64px;
}

.arrow {
  cursor: pointer;
  width: 30px;
  transform: translate(4px, -2px);
  vertical-align: bottom;
  -webkit-filter: invert(0%);
}

.arrow_disabled {
  -webkit-filter: invert(50%);
}

.row_title {
  display: flex;
  padding: 1%;
  padding-bottom: 0.5%;
  color: #606e81;
}

.row_container {
  border: 1px solid #9ea6b4;
  border-radius: 20px 20px 20px 20px;
  color: #0c2340;
}

.rows {
  display: flex;
  padding: 1%;
  border-bottom: 1px solid #9ea6b4;
}

.rows:last-child {
  border: none;
}

.threeDots {
  margin: auto;
  width: 20px;
}

.large_column {
  flex: 3.5;
}

.medium_column {
  flex: 1.5;
}

.small_column {
  flex: 0.7;
}

.form {
  width: 60%;
}

.form>div {
  margin-top: 20px;
}

.form>*>input {
  width: 100%;
}

.confirm {
  float: right;
  margin-top: 30px;
}

.error {
  color: var(--color_negative);
}

.valid {
  color: green;
}

#addMember>h2 {
  margin: 1% 0%;
}

#invite_member_input_text {
  width: 250px
}
</style>
